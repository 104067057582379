import { Loadable } from '@gdp/react-app/lib/helpers/loadable';
import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFound } from 'src/app/components/NotFound';
import {
  BRANCH,
  BROADCAST_TEMPLATE,
  CONFIGURATION,
  CONTACT,
  CUSTOMER,
  DASHBOARD,
  DEPOSIT,
  DRIVER,
  DRIVER_DASHBOARD,
  DRIVER_FEE,
  EXPENSE,
  ITEM,
  LIVE_ORDER,
  LOGIN,
  MASTER_CONFIG,
  ORDER,
  ORDER_CONFIGURATION,
  ORDER_TRACKING,
  PROFILE,
  ROLE,
  USER,
  VEHICLE
} from 'src/app/constants/route';
import { LoadableBranchRoutes } from 'src/app/containers/authenticated/branch';
import { LoadableBroadcastTemplateRoutes } from 'src/app/containers/authenticated/broadcasttemplate';
import { LoadableContactRoutes } from 'src/app/containers/authenticated/contact';
import { LoadableCustomerRoutes } from 'src/app/containers/authenticated/customer';
import { LoadableConfigurationRoutes } from 'src/app/containers/authenticated/deliveryoperational';
import { LoadableDepositRoutes } from 'src/app/containers/authenticated/deposit';
import { LoadableDriverRoutes } from 'src/app/containers/authenticated/driver';
import { LoadableDriverDashboardRoutes } from 'src/app/containers/authenticated/driverdashboard';
import { LoadableDriverFeeRoutes } from 'src/app/containers/authenticated/driverfee';
import { LoadableExpenseRoutes } from 'src/app/containers/authenticated/expense';
import { LoadableItemRoutes } from 'src/app/containers/authenticated/item';
import { LoadableLiveOrderRoutes } from 'src/app/containers/authenticated/liveorder';
import { LoadableMasterConfigRoutes } from 'src/app/containers/authenticated/masterconfig';
import { LoadableOrderRoutes } from 'src/app/containers/authenticated/order';
import { LoadableOrderConfigurationRoutes } from 'src/app/containers/authenticated/orderconfiguration';
import { LoadableProfileRoutes } from 'src/app/containers/authenticated/profile';
import { LoadableRoleRoutes } from 'src/app/containers/authenticated/role';
import { LoadableUserRoutes } from 'src/app/containers/authenticated/user';
import { LoadableVehicleRoutes } from 'src/app/containers/authenticated/vehicle';


const routes = [
  {
    component: Loadable(
      '../../containers/Login',
      () => import(/* webpackChunkName: "Login" */ '../../containers/Login'),
      () => [(require as any).resolveWeak('../../containers/Login')]
    ),
    isExact: true,
    path: LOGIN.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/Dashboard',
      () => import(/* webpackChunkName: "Dashboard" */ '../../containers/authenticated/Dashboard'),
      () => [(require as any).resolveWeak('../../containers/authenticated/Dashboard')]
    ),
    isExact: true,
    path: DASHBOARD.path
  },
  {
    component: Loadable(
      '../../containers/OrderTracking',
      () => import(/* webpackChunkName: "OrderTracking" */ '../../containers/OrderTracking'),
      () => [(require as any).resolveWeak('../../containers/OrderTracking')]
    ),
    isExact: true,
    path: ORDER_TRACKING.path
  },
  {
    component: LoadableRoleRoutes,
    isExact: false,
    path: ROLE.path
  },
  {
    component: LoadableProfileRoutes,
    isExact: false,
    path: PROFILE.path
  },
  {
    component: LoadableUserRoutes,
    isExact: false,
    path: USER.path
  },
  {
    component: LoadableBranchRoutes,
    isExact: false,
    path: BRANCH.path
  },
  {
    component: LoadableContactRoutes,
    isExact: false,
    path: CONTACT.path
  },
  {
    component: LoadableItemRoutes,
    isExact: false,
    path: ITEM.path
  },
  {
    component: LoadableCustomerRoutes,
    isExact: false,
    path: CUSTOMER.path
  },
  {
    component: LoadableLiveOrderRoutes,
    isExact: false,
    path: LIVE_ORDER.path
  },
  {
    component: LoadableBroadcastTemplateRoutes,
    isExact: false,
    path: BROADCAST_TEMPLATE.path
  },
  {
    component: LoadableOrderRoutes,
    isExact: false,
    path: ORDER.path
  },
  {
    component: LoadableDepositRoutes,
    isExact: false,
    path: DEPOSIT.path
  },
  {
    component: LoadableDriverRoutes,
    isExact: false,
    path: DRIVER.path
  },
  {
    component: LoadableDriverDashboardRoutes,
    isExact: false,
    path: DRIVER_DASHBOARD.path
  },
  {
    component: LoadableDriverFeeRoutes,
    isExact: false,
    path: DRIVER_FEE.path
  },
  {
    component: LoadableOrderConfigurationRoutes,
    isExact: false,
    path: ORDER_CONFIGURATION.path
  },
  {
    component: LoadableConfigurationRoutes,
    isExact: false,
    path: CONFIGURATION.path
  }, {
    component: LoadableVehicleRoutes,
    isExact: false,
    path: VEHICLE.path
  },
  {
    component: LoadableExpenseRoutes,
    isExact: false,
    path: EXPENSE.path
  },
  {
    component: LoadableMasterConfigRoutes,
    isExact: false,
    path: MASTER_CONFIG.path
  }
];

@withStyles(require('./Routes.scss'))
export class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {routes.map((r: any, index: number) => (
          <Route key={index} path={r.path} exact={r.isExact} component={r.component} />
        ))}
        <Route component={NotFound} />
      </Switch>
    );
  }
}
